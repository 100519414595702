import { Component, Fragment, useState } from "react";
import * as PropTypes from "prop-types";
import * as algoliasearch from "algoliasearch/lite";
import * as React from "react";
import {
  Button,
  Heading,
  Link,
  List,
  Page,
  PageSection,
  Tile
} from "../components";

import "./EStatementCommunities.css";

const ALGOLIA_INDEX_NAME = "Community Lookup";

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
const searchIndex = algoliaClient.initIndex(ALGOLIA_INDEX_NAME);

interface CommunitySearchResult {
  "Address 1": string;
  "Address 2": string;
  "Building Name": string;
  City: string;
  ME: string;
  ME_Email: string;
  ME_PhoneNo: string;
  RM: string;
  RM_Email: string;
  RM_Phone: string;
  State: string;
  Website_URL: string;
  Zip: string;
  eStatements: string;
}

interface State {
  eStatementCommunities: CommunitySearchResult[];
}

class EStatementCommunities extends Component<{}, State> {
  public constructor(props) {
    super(props);

    this.state = {
      eStatementCommunities: []
    };
  }

  public async componentDidMount() {
    try {
      const results = await searchIndex.search<CommunitySearchResult>({
        facetFilters: ["eStatements:true"],
        length: 1000 /* Maximum */,
        offset: 0 /* Required to use length query */
      });

      if (results.nbHits >= 1000) {
        console.warn("Maximum community results returned.");
      }

      this.setState({
        eStatementCommunities: results.hits
      });
    } catch (err) {
      console.error(err);
    }
  }

  public render() {
    const communityListItems = this.state.eStatementCommunities.map(
      community => (
        //@ts-ignore objectID is assigned by algolia automatically
        <li key={community.objectID}>{community["Building Name"]}</li>
      )
    );
    return (
      <Page title="eStatement Communities" className="estatement-communities">
        <PageSection
          style={{
            background:
              "linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 100%), linear-gradient(90deg, rgba(46,119,187,0.18) 0%, rgb(121, 188, 110, 0.6) 100%)"
          }}
        >
          <div style={{ textAlign: "center", maxWidth: 788, margin: "auto" }}>
            <Heading level={1}>eStatement Communities</Heading>
            <div style={{ textAlign: "left" }}>
              <p>
                {`If your association is listed below, you are eligible for
                e-Statements. Hawaiiana’s paperless statement service allows you
                to quickly and securely view your association's monthly billing
                statements online.`}
              </p>
              <p>
                If your community is listed below and you have questions or
                concerns regarding eStatement,{" "}
                <Link href="/estatement-customer-service">click here</Link>.
              </p>
              <p>
                If your community is not listed below, your association
                currently uses coupons for payment, and eStatements are not
                available. Sign up below.
              </p>
            </div>

            <Button href="https://docsight.net/xdsweb/OENLogon.aspx?ID=3105">
              Access eStatements
            </Button>
          </div>
          <Tile className="estatement-communities__community-list-container">
            <Heading level={3}>Properties With eStatements Available</Heading>
            <hr />
            <List className="estatement-communities__community-list">
              {communityListItems}
            </List>
          </Tile>
        </PageSection>
        <PageSection
          style={{
            background:
              "linear-gradient(90deg, rgba(46,119,187,0.6) 0%, rgb(121, 188, 110, 1) 100%)"
          }}
        >
          <Tile
            style={{
              maxWidth: 720,
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center"
            }}
          >
            <Heading level={3} style={{ marginTop: 0 }}>
              For assistance or questions on the eStatement service
            </Heading>
            <Button href="/estatement-customer-service">
              eStatement Inquiry Form
            </Button>
          </Tile>
        </PageSection>
      </Page>
    );
  }
}

export default EStatementCommunities;
